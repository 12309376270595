<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card
          className="iq-card-transparent iq-card-block iq-card-stretch iq-card-height rounded"
        >
          <div class="newrealease-contens">
            <Slick id="newrealease-slider" :option="option1">
              <li v-for="(image, index) in images" :key="index" class="item">
                <a href="#">
                  <img
                    :src="image.src"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                </a>
              </li>
            </Slick>
          </div>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <iq-card
          className=" iq-card-block iq-card-stretch iq-card-height"
          headerClass=" align-items-center position-relative"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title mb-0 title">Browse Books</h4>
          </template>
          <template v-slot:headerAction>
            <router-link
              to="/category-search"
              class="btn btn-sm btn-primary view-more text-white"
              >View More</router-link
            >
          </template>
          <template v-slot:body>
            <b-row>
              <div class="col-sm-6 col-md-4 col-lg-3" v-for="book in topBooks" :key="book">
                <div
                  class="iq-card iq-card-block iq-card-stretch iq-card-height browse-bookcontent"
                >
                  <div class="iq-card-body p-0">
                    <div class="d-flex align-items-center">
                      <div
                        class="col-6 p-0 position-relative image-overlap-shadow"
                      >
                        <a href="#"
                          ><img
                            class="img-fluid rounded w-100"
                            style="height: 250px"
                            :src=book.cover_url
                            :alt="book.title"
                        /></a>
                        <div class="view-book">
                          <router-link
                            :to="`/book/${book.slug}`"
                            class="btn btn-sm btn-white"
                            >View Book</router-link
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-2">
                          <h6 class="mb-1 text-capitalize subtitle">{{ book.title }}</h6>
                          <p class="font-size-13 line-height mb-1">
                            <span v-for="authorDetail in book.author" :key="authorDetail.slug">{{ authorDetail.name }}</span>
                          </p>
                          <div class="d-block line-height">
                            <span class="font-size-11 text-warning">
                              <i class="fa fa-star" v-for="rating in book.ratings_average" :key="rating"></i>
                            </span>
                          </div>
                        </div>
                        <div class="price truncated d-flex align-items-center">
                          <span class="pr-1" v-if="book.summary != 'No description for this book yet'">{{ book.summary != 'No description for this book yet' ? book.summary : book.excerpt }}</span>
                        </div>
                        <div class="iq-product-action">
                          <!-- <a href="#"
                            ><i class="ri-shopping-cart-2-fill text-primary"></i
                          ></a> -->
                          <!-- <a href="#" class="ml-2"
                            ><i class="ri-heart-fill text-danger"></i
                          ></a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="6">
        <iq-card
          className=" iq-card-block iq-card-stretch iq-card-height"
          headerClass=" mb-0"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Library's Pick</h4>
          </template>
          <template v-slot:headerAction>
            <b-dropdown
              id="dropdownMenuButton1"
              right
              variant="none"
              class="d-none"
              data-toggle="dropdown"
            >
              <template v-slot:button-content >
                This Week<i class="ri-arrow-down-s-fill"></i>
              </template>
              <b-dropdown-item
                ><i class="ri-eye-fill mr-2"></i
                >{{ $t("dropdown.view") }}</b-dropdown-item
              >
              <b-dropdown-item
                ><i class="ri-delete-bin-6-fill mr-2"></i
                >{{ $t("dropdown.delete") }}</b-dropdown-item
              >
              <b-dropdown-item
                ><i class="ri-pencil-fill mr-2"></i
                >{{ $t("dropdown.edit") }}</b-dropdown-item
              >
              <b-dropdown-item
                ><i class="ri-printer-fill mr-2"></i
                >{{ $t("dropdown.print") }}</b-dropdown-item
              >
              <b-dropdown-item
                ><i class="ri-file-download-fill mr-2"></i
                >{{ $t("dropdown.download") }}</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template v-slot:body>
            <div class="row align-items-center">
              <div class="col-sm-5 pr-0">
                <a href="#"
                  ><img
                    class="img-fluid rounded w-100"
                    :src=editorChoice.cover_url
                    :alt="editorChoice.title"
                /></a>
              </div>
              <div class="col-sm-7 mt-3 mt-sm-0">
                <h4 class="mb-2 subtitle">{{ editorChoice.title }}</h4>
                <p class="mb-2">Author: <span v-for="authorDetail in editorChoice.author" :key="authorDetail.slug">{{ authorDetail.name }}</span></p>
                <div class="mb-2 d-block">
                  <span class="font-size-12 text-warning">
                    <i class="fa fa-star" v-for="rating in editorChoice.ratings_average" :key="rating"></i>
                  </span>
                </div>
                <span class="text-dark mb-3 d-block extended-trunc"
                  >
                  <span class="pr-1">

                  <span v-if="isMarkdown(editorChoice.summary)">
                    <span v-html="markdownText(editorChoice.summary)"></span>
                  </span>

                  <span v-else>{{ editorChoice.summary }}</span>
                </span>
                  </span
                >
                  <router-link
                            :to="`/book/${editorChoice.slug}`"
                            class="btn btn-sm btn-primary learn-more"
                            >{{featuredTag}}</router-link
                          >

              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="6">
        <iq-card
          className="iq-card-block iq-card-stretch iq-card-height"
          headerClass=" mb-0"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">{{feturedAuthorTitle}} {{ featuredAuthor.name }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-dropdown
              id="dropdownMenuButton1"
              right
              variant="none"
              class="d-none"
              data-toggle="dropdown"
            >
              <template v-slot:button-content>
                This Week<i class="ri-arrow-down-s-fill"></i>
              </template>
              <b-dropdown-item
                ><i class="ri-eye-fill mr-2"></i
                >{{ $t("dropdown.view") }}</b-dropdown-item
              >
              <b-dropdown-item
                ><i class="ri-delete-bin-6-fill mr-2"></i
                >{{ $t("dropdown.delete") }}</b-dropdown-item
              >
              <b-dropdown-item
                ><i class="ri-pencil-fill mr-2"></i
                >{{ $t("dropdown.edit") }}</b-dropdown-item
              >
              <b-dropdown-item
                ><i class="ri-printer-fill mr-2"></i
                >{{ $t("dropdown.print") }}</b-dropdown-item
              >
              <b-dropdown-item
                ><i class="ri-file-download-fill mr-2"></i
                >{{ $t("dropdown.download") }}</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template v-slot:body>
          <article v-if="isMarkdown(featuredAuthor.bio)">
            <div v-html="markdownText(featuredAuthor.bio)"></div>
          </article>
          <article v-else> {{ featuredAuthor.bio }}</article>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <iq-card
          className=" iq-card-block iq-card-stretch iq-card-height"
          headerClass=" align-items-center position-relative"
          bodyClass="favorites-contens"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title mb-0">Favorite Reads</h4>
          </template>
          <template v-slot:headerAction>
            <router-link
              to="category-search"
              class="btn text-white btn-sm btn-primary view-more"
              >View More</router-link
            >
          </template>
          <template v-slot:body>
            <Slick id="favorites-slider" :option="option">
              <li class="d-flex align-items-center">
                <div class="col-5 p-0 position-relative">
                  <a href="#">
                    <img
                      src="../../assets/images/favorite/01.jpg"
                      class="img-fluid rounded w-100"
                      alt=""
                    />
                  </a>
                </div>
                <div class="col-7">
                  <h5 class="mb-2 subtitle">Every Book is a new Wonderful Travel..</h5>
                  <p class="mb-2">Author : Pedro Araez</p>
                  <div
                    class="d-flex justify-content-between align-items-center text-dark font-size-13"
                  >
                    <span>Reading</span>
                    <span class="mr-4">78%</span>
                  </div>
                  <div class="iq-progress-bar-linear d-inline-block w-100">
                    <b-progress
                      :value="78"
                      class="iq-progress-bar"
                      variant="primary"
                    />
                  </div>
                  <a href="#" class="text-dark"
                    >Read Now<i class="ri-arrow-right-s-line"></i
                  ></a>
                </div>
              </li>

              <li class="d-flex align-items-center">
                <div class="col-5 p-0 position-relative">
                  <a href="#">
                    <img
                      src="../../assets/images/favorite/02.jpg"
                      class="img-fluid rounded w-100"
                      alt=""
                    />
                  </a>
                </div>
                <div class="col-7">
                  <h5 class="mb-2 subtitle">Casey Christie night book into find...</h5>
                  <p class="mb-2">Author : Michael klock</p>
                  <div
                    class="d-flex justify-content-between align-items-center text-dark font-size-13"
                  >
                    <span>Reading</span>
                    <span class="mr-4">78%</span>
                  </div>
                  <div class="iq-progress-bar-linear d-inline-block w-100">
                    <b-progress
                      :value="78"
                      class="iq-progress-bar"
                      variant="danger"
                    />
                  </div>
                  <a href="#" class="text-dark"
                    >Read Now<i class="ri-arrow-right-s-line"></i
                  ></a>
                </div>
              </li>

              <li class="d-flex align-items-center">
                <div class="col-5 p-0 position-relative">
                  <a href="#">
                    <img
                      src="../../assets/images/favorite/03.jpg"
                      class="img-fluid rounded w-100"
                      alt=""
                    />
                  </a>
                </div>
                <div class="col-7">
                  <h5 class="mb-2">The Secret to English Busy People..</h5>
                  <p class="mb-2">Author : Daniel Ace</p>
                  <div
                    class="d-flex justify-content-between align-items-center text-dark font-size-13"
                  >
                    <span>Reading</span>
                    <span class="mr-4">78%</span>
                  </div>
                  <div class="iq-progress-bar-linear d-inline-block w-100">
                    <b-progress
                      :value="78"
                      class="iq-progress-bar"
                      variant="info"
                    />
                  </div>
                  <a href="#" class="text-dark"
                    >Read Now<i class="ri-arrow-right-s-line"></i
                  ></a>
                </div>
              </li>

              <li class="d-flex align-items-center">
                <div class="col-5 p-0 position-relative">
                  <a href="#">
                    <img
                      src="../../assets/images/favorite/04.jpg"
                      class="img-fluid rounded w-100"
                      alt=""
                    />
                  </a>
                </div>
                <div class="col-7">
                  <h5 class="mb-2">The adventures of Robins books...</h5>
                  <p class="mb-2">Author : Luka Afton</p>
                  <div
                    class="d-flex justify-content-between align-items-center text-dark font-size-13"
                  >
                    <span>Reading</span>
                    <span class="mr-4">78%</span>
                  </div>
                  <div class="iq-progress-bar-linear d-inline-block w-100">
                    <b-progress
                      :value="78"
                      class="iq-progress-bar"
                      variant="success"
                    />
                  </div>
                  <a href="#" class="text-dark"
                    >Read Now<i class="ri-arrow-right-s-line"></i
                  ></a>
                </div>
              </li>
            </Slick>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import { chooseRandomElements } from '../../utils'
import axios from 'axios'
import MarkdownIt from 'markdown-it'
import { mapGetters } from 'vuex'
const $ = require('jquery')
export default {
  name: 'Home',
  created () {
    this.getTopBooks()
    // this.getTopAuthors()
    this.getEditorChoice()
  },
  mounted () {
    core.index()
    $('#newrealease-slider .slick-active.slick-center')
      .prev('.slick-active')
      .addClass('temp')

    $('#newrealease-slider .slick-active.temp')
      .prev()
      .addClass('temp-1')
    $('#newrealease-slider .slick-active.temp-1')
      .prev()
      .addClass('temp-2')
    $('#newrealease-slider .slick-active.slick-center')
      .next('.slick-active')
      .addClass('temp-next')
    $('#newrealease-slider .slick-active.temp-next')
      .next()
      .addClass('temp-next-1')
    $('#newrealease-slider .slick-active.temp-next-1')
      .next()
      .addClass('temp-next-2')
    $('#newrealease-slider').on('afterChange', function () {
      let SLICKSLIDER = $('.slick-active.slick-center').data('slick-index')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          (SLICKSLIDER - 1) +
          '"]'
      ).addClass('temp')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          (SLICKSLIDER - 2) +
          '"]'
      ).addClass('temp-1')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          (SLICKSLIDER - 3) +
          '"]'
      ).addClass('temp-2')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          parseInt(SLICKSLIDER + 1) +
          '"]'
      ).addClass('temp-next')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          parseInt(SLICKSLIDER + 2) +
          '"]'
      ).addClass('temp-next-1')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          parseInt(SLICKSLIDER + 3) +
          '"]'
      ).addClass('temp-next-2')
    })
    $('#newrealease-slider').on('beforeChange', function () {
      let SLICKSLIDER = $('.slick-active.slick-center').data('slick-index')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          (SLICKSLIDER - 1) +
          '"]'
      ).removeClass('temp')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          (SLICKSLIDER - 2) +
          '"]'
      ).removeClass('temp-1')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          (SLICKSLIDER - 3) +
          '"]'
      ).removeClass('temp-2')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          parseInt(SLICKSLIDER + 1) +
          '"]'
      ).removeClass('temp-next')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          parseInt(SLICKSLIDER + 2) +
          '"]'
      ).removeClass('temp-next-1')
      $(
        '#newrealease-slider .slick-active[data-slick-index="' +
          parseInt(SLICKSLIDER + 3) +
          '"]'
      ).removeClass('temp-next-2')
    })
  },
  computed: {
    ...mapGetters({
      rtl: 'Setting/rtlModeState',
      lang: 'Setting/langState'
    }),
    featuredAuthor () {
      return this.editorChoice.author.length > 0 ? chooseRandomElements(this.editorChoice.author, 1)[0] : { bio: '', name: '' }
    },
    featuredTag () {
      return chooseRandomElements(['Tell me more', 'Show more'], 1)[0]
    },
    feturedAuthorTitle () {
      return chooseRandomElements(['Who is ', 'About '], 1)[0]
    }

  },
  watch: {
    rtl: function () {
      this.option1.rtl = this.rtl
      this.option.rtl = this.rtl
    }
  },
  methods: {
    getTopBooks () {
      axios.get('books/topbooks/').then(response => {
        let books = response.data.results
        // this.images = chooseRandomElements(books, 25)
        this.topBooks = chooseRandomElements(books, 12)
      }).catch(({ _response }) => {
        this.errorOnFetch = true
      })
    },
    getTopAuthors () {
      axios.get('authors/topauthors').then(response => {
        this.topAuthors = chooseRandomElements(response.data.results, 8)
      }).catch((_e) => { this.errorOnFetch = true })
    },
    getEditorChoice () {
      axios.get('books/editorchoice/').then(response => {
        this.editorChoice = response.data[0]
      }).catch((_e) => { this.errorOnFetch = true })
    },
    isMarkdown (text) {
      // Check if the text contains any Markdown syntax
      const markdownRegex = /[#*_~`>-]|[|:-]+/
      return markdownRegex.test(text)
    },
    markdownText (text) {
      // Convert the Markdown text to HTML using markdown-it library
      const md = new MarkdownIt()
      return md.render(text)
    }
  },
  data () {
    return {
      errorOnFetch: false,
      topBooks: [],
      topAuthors: [],
      editorChoice: { author: [] },
      option: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        centerMode: false,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      option1: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        centerMode: true,
        centerPadding: false,
        variableWidth: true,
        focusOnSelect: true,
        autoplay: false,
        slidesToShow: 7,
        slidesToScroll: 1
      },
      images: [
        {
          src: require('../../assets/images/new_realeases/01.jpg')
        },
        {
          src: require('../../assets/images/new_realeases/02.jpg')
        },
        {
          src: require('../../assets/images/new_realeases/03.jpg')
        },
        {
          src: require('../../assets/images/new_realeases/04.jpg')
        },
        {
          src: require('../../assets/images/new_realeases/05.jpg')
        },
        {
          src: require('../../assets/images/new_realeases/06.jpg')
        },
        {
          src: require('../../assets/images/new_realeases/07.jpg')
        },
        {
          src: require('../../assets/images/new_realeases/08.jpg')
        },
        {
          src: require('../../assets/images/new_realeases/09.jpg')
        }
      ]
    }
  }
}
</script>
<style>
.truncated{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.extended-trunc{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
